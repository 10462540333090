import React from 'react';
import {
    Grid,
    Input,
    Select,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import ClassNames from 'classnames';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    CampusSelectOption,
    SelectOption,
} from '../../global/models';
import Utils from '../../global/utils/utils.js';
import { PersonalInformationPanelField } from '../models';
import {
    DOES_NOT_ATTEND_CHURCH_ENTITY,
    UNKNOWN_CHURCH_ENTITY,
} from './constants';
import Gender from './models/gender.model';
import {
    updateCampus,
    updateFirstName,
    updateGender,
    updateLastName,
    updatePersonalData,
} from './personalForm.actions';
import { PersonalFormState } from './personalForm.reducer';

type PropTypes = {
    /**
     * Determines campus List
     */
    campusOptions: CampusSelectOption[];
    /**
     * Toggle for lite Personal details (no gender field, currently)
     */
    isLite?: boolean;
    /**
     * Personal Form Section data from Redux
     */
    personalForm: PersonalFormState;
    /**
     * List of required fields (if any) for the Form's Personal Panel
     */
    requiredFields: PersonalInformationPanelField[];
    /**
     * Whether or not to show required field validation errors (i.e. did user attempt to submit form entry)
     */
    showValidationErrors: boolean;
    /**
     * Update campus for personal form
     */
    updateCampus: Function;
    /**
     * Update firstname for personal form
     */
    updateFirstName: Function;
    /**
     * Update gender for personal form
     */
    updateGender: Function;
    /**
     * Update lastname for personal form
     */
    updateLastName: Function;
};

const mapStateToProps = (state) => {
    const {
        connectionForms: {
            publicForm: {
                personalForm,
            },
        },
    } = state;

    return {
        personalForm,
    };
};

const mapDispatchToProps = {
    updateCampus,
    updateFirstName,
    updateGender,
    updateLastName,
    updatePersonalData,
};

const useStyles = makeStyles(({
    palette,
    spacing,
}) => ({
    container: {
        marginBottom: spacing(2),
    },
    grid: {
        marginBottom: [-16.5, '!important'],
        marginTop: [-16.6, '!important'],
    },
    gridColumn: {
        paddingBottom: [16.5, '!important'],
        paddingTop: [16.6, '!important'],
    },
    labelColor: {
        '& label': {
            color: palette.grey[500],
        },
    },
    sectionTitle: {
        paddingBottom: [0, '!important'],
    },
}));

export const BEM_BLOCK_NAME = 'connection_form_public--personal_form';

const GENDER_OPTIONS: SelectOption<Gender, Gender>[] = [
    {
        label: Gender.Female,
        value: Gender.Female,
    }, {
        label: Gender.Male,
        value: Gender.Male,
    },
];

function PersonalForm(props: PropTypes) {
    const {
        campusOptions,
        isLite,
        personalForm: {
            campus,
            firstName,
            gender,
            lastName,
        },
        requiredFields,
        showValidationErrors,
        updateCampus: updateCampusAction,
        updateFirstName: updateFirstNameAction,
        updateGender: updateGenderAction,
        updateLastName: updateLastNameAction,
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const localizedGenderOptions = GENDER_OPTIONS.map((genderOption) => ({
        ...genderOption,
        label: t(`personalForm.${genderOption.label.toLowerCase()}`),
    }));

    const localizedCampusOptions = campusOptions?.map((campusOption) => {
        switch (campusOption.value) {
            case DOES_NOT_ATTEND_CHURCH_ENTITY:
                return {
                    ...campusOption,
                    label: t('personalForm.doesNotAttendCampusOption'),
                };

            case UNKNOWN_CHURCH_ENTITY:
                return {
                    ...campusOption,
                    label: t('personalForm.unknownCampusOption'),
                };

            default:
                return campusOption;
        }
    });

    const sectionTitleClasses = ClassNames(classes.gridColumn, classes.sectionTitle);

    const onChangeCampus = (selectedCampus) => {
        updateCampusAction(selectedCampus.value);
    };

    const onChangeGender = (selectedGender) => {
        updateGenderAction(selectedGender.value);
    };

    const isFirstNameRequired = requiredFields.includes(PersonalInformationPanelField.FirstName);
    const hasFirstName = !Utils.isStringNullOrWhiteSpace(firstName);

    const isLastNameRequired = requiredFields.includes(PersonalInformationPanelField.LastName);
    const hasLastName = !Utils.isStringNullOrWhiteSpace(lastName);

    const isGenderRequired = requiredFields.includes(PersonalInformationPanelField.Gender);
    const hasGender = !isNil(gender);

    const isCampusRequired = requiredFields.includes(PersonalInformationPanelField.Campus);
    const hasCampus = campus > 0 || campus === DOES_NOT_ATTEND_CHURCH_ENTITY;

    const localizedRequiredText = t('common.terms.required');
    const localizedSelectText = t('common.terms.select');

    return (
        <div className={classes.container}>
            <Grid
                className={classes.grid}
                spacing={2}
            >
                <Grid.Column
                    className={sectionTitleClasses}
                    sm={12}
                >
                    <Typography
                        gutterBottom
                        variant="h3"
                    >
                        {t('personalForm.personal')}
                    </Typography>
                </Grid.Column>

                <Grid.Column
                    className={classes.gridColumn}
                    md={6}
                    sm={12}
                >
                    <Input
                        className={classes.labelColor}
                        dataTestId={`${BEM_BLOCK_NAME}_field_firstname`}
                        error={
                            showValidationErrors && isFirstNameRequired && !hasFirstName ?
                                localizedRequiredText :
                                undefined
                        }
                        fluid
                        id={`${BEM_BLOCK_NAME}_field_firstname`}
                        label={t('personalForm.firstName')}
                        onChange={updateFirstNameAction}
                        required={isFirstNameRequired}
                        tabIndex={0}
                        type="text"
                        value={firstName}
                    />
                </Grid.Column>

                <Grid.Column
                    className={classes.gridColumn}
                    md={6}
                    sm={12}
                >
                    <Input
                        className={classes.labelColor}
                        dataTestId={`${BEM_BLOCK_NAME}_field_lastname`}
                        error={
                            showValidationErrors && isLastNameRequired && !hasLastName ?
                                localizedRequiredText :
                                undefined
                        }
                        fluid
                        id={`${BEM_BLOCK_NAME}_field_lastname`}
                        label={t('personalForm.lastName')}
                        onChange={updateLastNameAction}
                        required={isLastNameRequired}
                        tabIndex={0}
                        type="text"
                        value={lastName}
                    />
                </Grid.Column>

                {!isLite && (
                    <Grid.Column
                        className={classes.gridColumn}
                        md={6}
                        sm={12}
                    >
                        <Select
                            className={classes.labelColor}
                            data-testid={`${BEM_BLOCK_NAME}_field_gender`}
                            error={
                                showValidationErrors && isGenderRequired && !hasGender ?
                                    localizedRequiredText :
                                    undefined
                            }
                            fluid
                            id={`${BEM_BLOCK_NAME}_field_gender`}
                            label={t('personalForm.gender')}
                            onChange={onChangeGender}
                            options={localizedGenderOptions}
                            placeholder={localizedSelectText}
                            required={isGenderRequired}
                            searchable
                            tabIndex={0}
                            value={gender}
                        />
                    </Grid.Column>
                )}

                <Grid.Column
                    className={classes.gridColumn}
                    md={6}
                    sm={12}
                >
                    <Select
                        className={classes.labelColor}
                        data-testid={`${BEM_BLOCK_NAME}_field_campus`}
                        error={
                            showValidationErrors && isCampusRequired && !hasCampus ?
                                localizedRequiredText :
                                undefined
                        }
                        fluid
                        id={`${BEM_BLOCK_NAME}_field_campus`}
                        label={t('personalForm.campus')}
                        onChange={onChangeCampus}
                        options={localizedCampusOptions}
                        placeholder={localizedSelectText}
                        required={isCampusRequired}
                        searchable
                        tabIndex={0}
                        value={localizedCampusOptions?.find((c) => c.value === campus) ?? null}
                    />
                </Grid.Column>
            </Grid>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalForm);
