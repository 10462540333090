import { isNil } from 'lodash';
import ActionType from './connectionForm.actionTypes';

export type EntryFormSaveState = {
    status: Number;
    isSaving: Boolean;
};

export const DEFAULT_STATE: EntryFormSaveState = {
    status: null,
    isSaving: false,
};

interface RequestFormEntrySaveAction {
    type: ActionType.REQUEST_FORM_ENTRY_SAVE;
}

interface FormEntrySavedSuccessAction {
    type: ActionType.FORM_ENTRY_SAVE_COMPLETED;
    result: Number;
}

interface FormEntrySaveErrorAction {
    type: ActionType.FORM_ENTRY_SAVE_ERROR;
    status: number;
}

type KnownActionType =
    RequestFormEntrySaveAction |
    FormEntrySavedSuccessAction |
    FormEntrySaveErrorAction;

export default (
    state: EntryFormSaveState | undefined,
    incomingAction: any,
): EntryFormSaveState => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    const action = incomingAction as KnownActionType;

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.REQUEST_FORM_ENTRY_SAVE:
                return {
                    ...state,
                    isSaving: true,
                };

            case ActionType.FORM_ENTRY_SAVE_COMPLETED:
            {
                const specificAction = action as FormEntrySavedSuccessAction;

                return {
                    ...state,
                    status: specificAction.result,
                    isSaving: false,
                };
            }

            case ActionType.FORM_ENTRY_SAVE_ERROR:
                return {
                    ...state,
                    isSaving: false,
                    status: action.status,
                };

            default:
                return state;
        }
    }

    return state;
};
