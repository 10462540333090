import React from 'react';
import {
    Box,
    Button,
    Icon,
    Modal,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import {
    Trans,
    useTranslation,
} from 'react-i18next';
import { BEM_BLOCK_NAME } from './constants';

type PropTypes = {
    hasAnyRequiredFields: boolean;
    isOpen: boolean;
    onClose: () => void;
};

const useStyles = makeStyles(({
    spacing,
}) => ({
    modalActionsMargin: {
        margin: [[spacing(5), spacing(4), 0]],
    },
    modalTitle: {
        marginTop: spacing(2),
    },
}));

function MissingRequiredFieldsModal({
    hasAnyRequiredFields,
    isOpen,
    onClose,
}: PropTypes) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={isOpen}
        >
            <Modal.Content
                alignItems="center"
            >
                <Icon
                    color="warning"
                    compact
                    size={64}
                    title={false}
                    type="exclamation"
                />

                {hasAnyRequiredFields ? (
                    <Typography
                        classes={{
                            root: classes.modalTitle,
                        }}
                        variant="h2"
                    >
                        {t('requiredFieldsModal.missingRequiredFields.title')}
                    </Typography>
                ) : (
                    <Typography
                        classes={{
                            root: classes.modalTitle,
                        }}
                        variant="h2"
                    >
                        {t('requiredFieldsModal.noDataWhatsoever.title')}
                    </Typography>
                )}

                <Box mx={4}>
                    {hasAnyRequiredFields ? (
                        <Typography
                            align="center"
                            color="textSecondary"
                            variant="subtitle1"
                        >
                            <Trans i18nKey="requiredFieldsModal.missingRequiredFields.body" />
                        </Typography>
                    ) : (
                        <Typography
                            align="center"
                            color="textSecondary"
                            variant="subtitle1"
                        >
                            <Trans i18nKey="requiredFieldsModal.noDataWhatsoever.body" />
                        </Typography>
                    )}
                </Box>
            </Modal.Content>

            <Modal.Actions
                alignItems="center"
                classes={{
                    margin: classes.modalActionsMargin,
                }}
                direction="column"
            >
                <Button
                    color="secondary"
                    designVersion={2}
                    fullWidth
                    id={`${BEM_BLOCK_NAME}--missing_required_fields_modal_ok_button`}
                    onClick={onClose}
                    tabIndex={0}
                >
                    {t('common.buttons.ok')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default MissingRequiredFieldsModal;
