import {
    find,
    isEmpty,
    isNil,
    noop,
} from 'lodash';
import ActionType from './connectionForm.actionTypes';
import {
    getAppSettings as fetchAppSettings,
    getCampusList as fetchCampuses,
    getConnectionFormTemplate as fetchConnectionFormTemplate,
    getCountryList as fetchCountries,
    getEventOccurrences as fetchEventOccurrences,
    getPersonInfo as fetchPersonInfo,
    getRegionsByCountry as fetchRegions,
    getWorshipServices as fetchWorshipServices,
    saveConnectionFormEntry as postFormEntry,
} from './connectionForm.service';
import ContactFormActionType from './contactForm.actionTypes';
import ConnectionFormEntry from './models/connectionFormEntry.model';
import ConnectionFormTemplate from './models/connectionFormTemplate.model';
import PersonalFormActionType from './personalForm.actionTypes';

export const getAppSettings = () => (dispatch: Function) => {
    dispatch({ type: ActionType.REQUEST_APP_SETTINGS });

    fetchAppSettings()
        .then((result) => {
            dispatch({
                result,
                type: ActionType.RECEIVE_APP_SETTINGS,
            });
        })
        .catch(noop);
};

export const getCampusList = () => (dispatch: Function) => {
    dispatch({ type: ActionType.REQUEST_CAMPUS_LIST });

    fetchCampuses()
        .then((result) => {
            dispatch({
                result,
                type: ActionType.RECEIVE_CAMPUS_LIST,
            });
        })
        .catch(noop);
};

export const getConnectionFormTemplate = (formTemplateId: number) => (dispatch: Function) => {
    dispatch({ type: ActionType.REQUEST_CONNECTION_FORM_TEMPLATE });

    fetchConnectionFormTemplate(formTemplateId)
        .then((result) => {
            const formTemplate = result.data as ConnectionFormTemplate;

            dispatch({
                formTemplate,
                status: result.status,
                type: ActionType.RECEIVE_CONNECTION_FORM_TEMPLATE,
            });
        })
        .catch((result) => {
            dispatch({
                type: ActionType.RECEIVE_CONNECTION_FORM_TEMPLATE_ERROR,
                status: result.response.status,
            });
        });
};

export const getCountryList = (locale?: string) => (dispatch: Function) => {
    dispatch({ type: ActionType.REQUEST_COUNTRIES_LIST });

    fetchCountries(locale)
        .then((result) => {
            dispatch({
                result,
                type: ActionType.RECEIVE_COUNTRIES_LIST,
            });
        })
        .catch(noop);
};

export const getPersonInfo = (accessToken: string, personId: number) => (dispatch: Function) => {
    if (isNil(accessToken)) {
        return;
    }

    dispatch({ type: ActionType.REQUEST_PERSON_DETAILS });

    fetchPersonInfo(personId, accessToken)
        .then((result) => {
            dispatch({
                result,
                type: ActionType.RECEIVE_PERSON_DETAILS,
            });
        })
        .catch(noop);
};

export const getRegionsByCountry = (
    countryId: string,
    locale?: string,
) => (dispatch: Function) => {
    dispatch({ type: ActionType.REQUEST_REGIONS_LIST });

    fetchRegions(countryId, locale)
        .then((result) => {
            dispatch({
                result,
                type: ActionType.RECEIVE_REGIONS_LIST,
            });
        })
        .catch(noop);
};

export const getWorshipService = (
    churchEntityId: number,
    dateRangeStart: string,
    dateRangeEnd: string,
    eventId: number,
) => (dispatch: Function) => {
    dispatch({ type: ActionType.REQUEST_WORSHIP_SERVICE });

    fetchWorshipServices(churchEntityId, dateRangeStart, dateRangeEnd)
        .then((results) => {
            const matchedWorshipService = find(results, (item) => item.eventId === eventId);

            dispatch({
                result: matchedWorshipService,
                type: ActionType.RECEIVE_WORSHIP_SERVICE,
            });
        })
        .catch(noop);
};

export const getEventOccurrence = (
    dateRangeStart: string,
    dateRangeEnd: string,
    eventId: number,
) => (dispatch: Function) => {
    dispatch({ type: ActionType.REQUEST_EVENT_OCCURRENCE });

    fetchEventOccurrences(dateRangeStart, dateRangeEnd, eventId)
        .then((results) => {
            dispatch({
                result: !isEmpty(results) ? results[0] : {},
                type: ActionType.RECEIVE_EVENT_OCCURRENCE,
            });
        })
        .catch(noop);
};

export const saveConnectionFormEntry = (
    accessToken: string | null,
    data: ConnectionFormEntry,
) => (dispatch: Function) => {
    dispatch({
        type: ActionType.REQUEST_FORM_ENTRY_SAVE,
    });

    postFormEntry(accessToken, data)
        .then((result) => {
            dispatch({
                type: ContactFormActionType.RESET_DATA,
            });

            dispatch({
                type: PersonalFormActionType.RESET_DATA,
            });

            dispatch({
                result: result.status,
                type: ActionType.FORM_ENTRY_SAVE_COMPLETED,
            });
        })
        .catch((result) => {
            dispatch({
                type: ActionType.FORM_ENTRY_SAVE_ERROR,
                status: result.response.status,
            });
        });
};
