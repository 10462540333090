/**
 * Contact Form - Phone Inputs
 */

import React, { memo } from 'react';
import {
    Grid,
    Input,
    PhoneInput,
    Radio,
} from '@saddlebackchurch/react-cm-ui';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { PhoneTypeProps } from '../../global/models/phoneType.model.js';
import Utils from '../../global/utils/utils.js';
import { PersonalInformationPanelField } from '../models';
import { Phone } from './contactForm.reducer.js';

type PropTypes = {
    /**
     * List of required fields (if any) for the Form's Personal Panel
     */
    requiredFields: PersonalInformationPanelField[];
    /**
     * Whether or not to show required field validation errors (i.e. did user attempt to submit form entry)
     */
    showValidationErrors: boolean;
    /**
     * Hide phone number field in contact form
     * FIXME: Temporary code to change the way the form renders for a campaign,
     * remove this and all related code when not longer needed
     * see: https://dev.azure.com/saddlebackchurch/Church%20Management/_workitems/edit/87959
     * and: https://dev.azure.com/saddlebackchurch/Church%20Management/_workitems/edit/87960
     */
    hidePhoneNumber?: boolean;
    /**
     * Phone number array
     */
    phone: Phone;
    /**
     * Update phone for contact form
     */
    setPhoneAction: Function;
    /**
     * Update phone extension for contact form
     */
    setPhoneExtensionAction: (phoneExtension: string) => void;
    /**
     * Update phone type for contact form
     */
    setPhoneTypeAction: (phoneType: PhoneTypeProps) => void;

};

const useStyles = makeStyles(({
    spacing,
}) => ({
    gridColumn: {
        paddingBottom: [16.5, '!important'],
        paddingTop: [16.6, '!important'],
    },
    phoneType: {
        alignItems: 'flex-end',
        display: 'flex',
        paddingBottom: [spacing(2), '!important'],
    },
}));

export const BEM_BLOCK_NAME = 'connection_form_public--contact_form';

const ContactPhoneNumbers = (props: PropTypes) => {
    const {
        hidePhoneNumber,
        requiredFields,
        showValidationErrors,
        phone: {
            extension: phoneExtensionValue,
            isHome,
            isMobile,
            isWork,
            phoneNumber: phoneNumberValue,
            isPhoneValid,
        },
        setPhoneAction,
        setPhoneExtensionAction,
        setPhoneTypeAction,
    } = props;

    const classes = useStyles();

    const { t } = useTranslation();

    const onPhoneChange = (value, formattedNumber, dialCode, countryCode, isValid) => {
        setPhoneAction({
            countryCode,
            displayPhoneNumber: formattedNumber,
            isValid,
            phoneNumber: value,
        });
    };

    const onPhoneTypeChange = (value) => {
        const isValueHomeId = value === `${BEM_BLOCK_NAME}--field_phone_input_home`;
        const isValueMobileId = value === `${BEM_BLOCK_NAME}--field_phone_input_mobile`;
        const isValueWorkId = value === `${BEM_BLOCK_NAME}--field_phone_input_work`;

        setPhoneTypeAction({
            isHome: isValueHomeId,
            isMobile: isValueMobileId,
            isWork: isValueWorkId,
        });
    };

    const isPhoneRequired = requiredFields
        .includes(PersonalInformationPanelField.SinglePhoneNumberWithSpecifiedType);

    const hasPhone = !Utils.isStringNullOrWhiteSpace(phoneNumberValue);
    const hasErrorOnPhone = hasPhone && !isPhoneValid;
    let phoneInputError = null;

    if (hasErrorOnPhone) {
        phoneInputError = t('contactForm.invalidPhoneNumber');
    } else if (showValidationErrors && isPhoneRequired && !hasPhone) {
        phoneInputError = t('common.terms.required');
    }

    if (hidePhoneNumber) return null;

    return (
        <React.Fragment>
            <Grid.Column
                className={classes.gridColumn}
                md={6}
                sm={12}
            >
                <PhoneInput
                    data-testid={`${BEM_BLOCK_NAME}--field_phone_input`}
                    error={phoneInputError}
                    fluid
                    id={`${BEM_BLOCK_NAME}--field_phone_input`}
                    label={t('contactForm.phone')}
                    onChange={onPhoneChange}
                    required={isPhoneRequired}
                    tabIndex={0}
                    value={phoneNumberValue}
                />
            </Grid.Column>

            {isWork && (
                <Grid.Column
                    className={classes.gridColumn}
                    md={3}
                    sm={12}
                >
                    <Input
                        dataTestId={`${BEM_BLOCK_NAME}--field_phone_input_extension`}
                        fluid
                        id={`${BEM_BLOCK_NAME}--field_phone_input_extension`}
                        label={t('contactForm.extension')}
                        maxLength={7}
                        onChange={setPhoneExtensionAction}
                        tabIndex={0}
                        type="text"
                        value={phoneExtensionValue}
                    />
                </Grid.Column>
            )}

            <Grid.Column
                className={classes.phoneType}
                sm={12}
            >
                <Radio
                    checked={isHome}
                    id={`${BEM_BLOCK_NAME}--field_phone_input_home`}
                    label={t('contactForm.home')}
                    name={`${BEM_BLOCK_NAME}--field_phone_input_home`}
                    onChange={onPhoneTypeChange}
                    tabIndex={0}
                />

                <Radio
                    checked={isMobile}
                    id={`${BEM_BLOCK_NAME}--field_phone_input_mobile`}
                    label={t('contactForm.mobile')}
                    name={`${BEM_BLOCK_NAME}--field_phone_input_mobile`}
                    onChange={onPhoneTypeChange}
                    tabIndex={0}
                />

                <Radio
                    checked={isWork}
                    id={`${BEM_BLOCK_NAME}--field_phone_input_work`}
                    label={t('contactForm.work')}
                    name={`${BEM_BLOCK_NAME}--field_phone_input_work`}
                    onChange={onPhoneTypeChange}
                    tabIndex={0}
                />
            </Grid.Column>
        </React.Fragment>
    );
};

export default memo(ContactPhoneNumbers);
